/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { hidden } from '../styles/utils';

const icons = {
  triangle: {
    shape: (
      <polygon
        strokeWidth="1px"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="14.921,2.27 28.667,25.5 1.175,25.5 "
      />
    ),
    viewBox: `0 0 30 30`,
  },
  circle: {
    shape: (
      <path d="M15,30A15,15,0,1,1,30,15,15,15,0,0,1,15,30ZM15,6.23A8.77,8.77,0,1,0,23.77,15,8.77,8.77,0,0,0,15,6.23Z" />
    ),
    viewBox: `0 0 30 30`,
  },
  arrowUp: {
    shape: (
      <React.Fragment>
        <path d="M15,30A15,15,0,1,1,30,15,15,15,0,0,1,15,30ZM15,6.23A8.77,8.77,0,1,0,23.77,15,8.77,8.77,0,0,0,15,6.23Z" />
      </React.Fragment>
    ),
    viewBox: `0 0 30 42`,
  },
  upDown: {
    shape: (
      <React.Fragment>
        <g>
          <g id="Layer_1">
            <g
              stroke-miterlimit="10"
              stroke="#000"
              stroke-width=".036"
              clip-rule="evenodd"
              fill-rule="evenodd"
            >
              <path d="m123.75 16.501c-9.792-6.84-19.044-10.26-28.044-10.404-9.108-0.144-18.648 4.14-25.848 9.648-7.344 5.436-13.788 14.004-17.1 23.22-3.276 9.18-4.464 22.788-2.196 31.536 2.16 8.64 7.272 15.264 15.336 20.16-1.62 7.164-2.592 13.392-3.06 18.828-0.576 5.436-0.756 7.236 0.432 13.572 1.224 6.3 3.564 14.004 7.02 23.688-16.308 19.584-29.412 37.404-40.284 54.288-10.908 16.668-19.404 29.088-24.12 46.008-4.716 17.027-8.424 43.201-3.924 55.188 4.536 11.736 18.324 15.553 30.672 15.336 12.348-0.322 26.136-6.012 42.48-17.063-5.832 6.875-9.828 15.803-12.276 27.359-2.484 11.557-5.58 28.477-2.196 41.184 3.312 12.529 8.676 23.545 22.356 33.732 13.86 10.115 41.868 24.48 59.58 26.713 17.496 2.051 35.82-6.373 45.108-14.006 9.071-7.775 9-20.051 9.647-31.967 0.576-12.023-1.512-24.984-6.12-39.457h12.276c-2.16 9.938-2.268 19.549-0.468 29.377 1.8 9.791 5.651 19.943 11.412 28.908 5.688 8.82 11.483 18.828 22.788 23.652 11.34 4.68 31.14 7.344 44.243 4.391 12.925-3.131 24.841-12.852 32.832-22.787 7.668-10.188 11.881-23.617 14.04-36.793 2.124-13.211 1.044-30.131-0.899-41.184-2.052-11.088-5.652-17.424-10.8-24.299-5.221-6.949-11.736-12.385-19.944-16.74 10.836 2.664 21.563 4.607 32.724 5.975 11.053 1.332 25.416 5.508 33.517 1.98 8.027-3.816 13.68-11.123 14.472-23.867 0.54-12.816-5.184-36.865-10.512-52.129-5.364-15.335-8.208-27.216-21.024-38.988-13.032-11.88-31.355-22.212-56.088-31.572 5.148-14.112 8.316-26.784 9.648-38.844 1.296-11.988 0.756-22.896-1.765-32.868 12.924-3.564 20.772-9.648 24.084-18.396 3.168-8.964 1.836-24.372-4.824-34.164-6.911-9.756-22.896-22.14-35.46-23.688-12.6-1.584-25.271 3.24-38.987 14.472-8.676-5.616-19.152-9.612-32.004-12.276-13.032-2.7-30.061-5.58-44.676-3.492-14.59 2.093-28.38 7.205-42.06 15.773z" />
              <path
                d="m146.25 39.865c-10.512-7.848-19.368-13.968-27.144-18.756-7.704-4.788-12.42-8.82-19.152-9.576-6.912-0.864-15.624 1.188-21.96 4.788-6.444 3.636-12.492 10.296-16.344 16.776-4.032 6.444-6.66 14.184-7.2 21.132-0.576 6.768 0.756 13.932 3.996 19.548 3.204 5.472 8.208 10.044 15.156 13.572-2.448 4.68-3.96 10.044-4.392 16.38-0.468 6.336 0.252 13.752 1.62 21.132 1.26 7.236 1.764 14.508 5.976 21.96 4.248 7.416 12.816 15.804 18.756 21.96 5.724 5.976 8.172 9.288 15.948 13.968 7.776 4.608 17.712 9.072 30.348 13.536-1.404-4.5-2.52-8.568-3.204-12.744-0.792-4.284-3.132-9.108-1.188-11.988 1.872-2.952 4.284-2.556 12.78-5.184 8.568-2.736 27.684-9.792 37.908-10.764 9.9-1.008 13.788 1.512 22.355 4.788 8.568 3.312 25.129 9.54 29.124 14.364 3.744 4.644-3.672 9.18-5.579 13.176-1.872 3.852-3.564 7.128-5.221 9.972 5.904 1.512 12.061 0.18 18.756-3.996 6.66-4.284 14.832-12.78 21.168-21.168 6.408-8.532 12.133-20.232 16.345-29.124 3.924-8.856 6.084-15.552 7.596-23.544 1.404-8.28 1.296-17.316 1.188-24.732-0.181-7.38-0.792-13.716-1.98-19.152 6.012-1.8 11.196-4.644 15.552-8.388 4.356-3.852 9.108-8.46 10.368-14.364 1.044-6.012 0.036-14.508-3.563-21.168-3.673-6.696-11.881-14.508-17.965-18.72-6.264-4.248-12.203-6.336-18.359-6.804-6.12-0.612-9.937-1.44-17.964 3.204-8.137 4.572-18.108 12.636-30.313 24.336l-6.804-3.204c1.728-1.26 3.996-2.988 6.408-5.184 2.376-2.196 5.292-4.86 8.352-7.992-7.596-4.176-16.056-7.056-25.523-9.18-9.648-2.16-20.952-3.312-31.141-3.168-10.332 0.108-20.772 1.44-29.52 3.996-8.64 2.484-15.912 5.976-21.96 10.764l19.944 14.364c-1.07 1.711-2.12 3.439-3.2 5.167z"
                fill="#A16121"
              />
              <path d="m129.08 50.233c-7.668-6.156-14.796-10.188-21.924-11.952-7.236-1.908-15.228-1.512-20.376 1.188-5.22 2.736-9.792 8.208-9.972 14.76-0.144 6.408 2.916 14.112 9.18 23.544 12.024-1.08 21.456-3.78 28.728-8.388 7.23-4.608 11.88-10.944 14.36-19.152z" />
              <path
                d="m122.71 51.457c-3.528-3.384-8.1-5.652-13.572-6.804-5.616-1.296-15.228-1.62-19.548-0.396-4.356 1.224-6.444 3.42-6.408 7.992 0.036 4.536 2.304 10.872 6.804 19.152 9.828-1.8 17.136-4.284 22.752-7.596 5.47-3.384 8.75-7.452 9.97-12.348z"
                fill="#834217"
              />
              <path d="m242.41 39.433c8.172-5.472 15.696-8.748 23.004-9.828 7.236-1.152 15.192-0.072 20.124 3.096 4.716 3.096 9 9.144 8.568 15.624-0.504 6.408-4.248 13.788-11.376 22.572-11.916-2.196-20.988-5.832-27.792-11.052-6.72-5.256-10.9-12.06-12.52-20.412z" />
              <path
                d="m248.67 41.233c3.889-2.988 8.425-4.86 14.148-5.472 5.652-0.792 15.191-0.18 19.476 1.44 4.177 1.62 6.048 3.996 5.616 8.532-0.504 4.5-3.348 10.548-8.532 18.432-9.647-2.7-16.775-5.832-21.96-9.684-5.16-3.888-8.07-8.209-8.76-13.248z"
                fill="#834217"
              />
              <path d="m129.73 135.52c1.548 9.108 9.756 15.336 18.36 13.86 8.568-1.476 14.256-10.044 12.708-19.188-1.584-9.108-9.792-15.336-18.36-13.86-8.61 1.47-14.29 10.04-12.71 19.19z" />
              <path
                d="m135.2 133.82c1.044 6.084 6.516 10.224 12.24 9.252s9.54-6.696 8.496-12.78-6.516-10.224-12.24-9.252c-5.73 0.98-9.54 6.7-8.5 12.78z"
                fill="#fff"
              />
              <path d="m139.56 136.92c0.756 4.356 4.644 7.308 8.748 6.624 4.068-0.72 6.804-4.824 6.048-9.144-0.756-4.356-4.644-7.308-8.748-6.624-4.07 0.72-6.81 4.78-6.05 9.14z" />
              <path d="m208.14 133.79c-0.611 9.252 5.94 17.208 14.616 17.784s16.236-6.444 16.849-15.696c0.611-9.216-5.94-17.172-14.616-17.748-8.72-0.57-16.24 6.45-16.85 15.67z" />
              <path
                d="m213.86 133.46c-0.396 6.156 3.96 11.448 9.756 11.844s10.836-4.284 11.232-10.44c0.396-6.156-3.96-11.484-9.757-11.88-5.79-0.35-10.8 4.33-11.23 10.48z"
                fill="#fff"
              />
              <path d="m217.39 137.46c-0.324 4.428 2.808 8.208 6.947 8.46 4.141 0.288 7.74-3.06 8.028-7.452s-2.844-8.172-6.984-8.46c-4.15-0.29-7.71 3.06-8 7.45z" />
              <path
                d="m168.61 164.86c-4.464 1.26-8.856 2.412-13.176 3.996-4.428 1.368-10.764 2.088-12.78 4.788-2.088 2.808 0.072 7.452 0.792 11.592 0.612 3.996 0.432 8.064 3.204 12.348 2.592 4.32 6.804 10.152 13.176 13.176 6.372 2.772 20.124 5.724 25.128 3.996 4.968-1.836 6.3-6.48 4.392-14.364-4.14-4.212-7.2-7.668-9.576-10.764-2.412-3.024-5.04-5.364-4.392-7.596 0.648-2.484 4.284-5.256 7.992-6.372 3.636-1.116 8.856-1.152 13.572-0.396 4.536 0.684 12.527 1.08 13.968 4.392 1.296 3.24-3.168 11.124-5.616 14.76-2.483 3.492-5.544 5.616-9.18 6.372-2.196 5.364-2.592 9-1.188 11.592 1.404 2.448 5.616 4.248 9.576 3.168 3.853-1.296 10.044-6.516 13.572-9.972 3.312-3.42 4.932-6.408 7.2-10.368 2.304-4.176 6.048-10.584 6.768-13.968 0.72-3.312 0.72-3.636-2.771-5.976-3.816-2.628-13.5-6.3-19.188-8.784-5.688-2.52-9.828-4.752-14.364-5.58-4.607-0.72-8.711-0.036-13.14 0.792-4.56 0.64-9.17 1.64-13.96 3.16z"
                fill="#834217"
              />
              <path
                d="m72.418 151.21c-6.12 7.812-12.672 16.848-19.944 27.504-7.452 10.62-17.244 25.704-23.544 35.928-6.228 10.08-10.152 15.408-13.572 24.335-3.636 8.857-6.264 19.404-7.596 28.729-1.404 9.217-2.88 19.801-0.396 26.748 2.556 6.877 9.144 12.133 15.192 14.363 5.76 2.053 11.016 1.297 19.944-1.584 9-2.879 19.944-8.063 33.516-15.551 0.468-13.465 2.412-26.1 5.976-38.736 3.528-12.707 9.972-25.955 15.156-36.287 5.112-10.369 10.044-18.577 15.192-25.165-7.812-5.076-14.868-10.8-21.564-17.568-6.694-6.84-12.814-14.19-18.358-22.72z"
                fill="#A16121"
              />
              <path
                d="m81.202 294.96c5.832-3.131 12.888-5.256 21.564-6.371 8.604-1.152 21.492-1.117 29.916-0.432 8.352 0.539 12.96 1.078 19.944 3.994 6.876 2.916 16.02 8.426 21.168 13.178 4.968 4.607 6.408 9.07 9.18 14.363 2.736 5.184 5.076 10.729 7.164 16.775l12.385 0.396c0.576-6.48 2.304-12.6 5.184-18.359 2.844-5.904 5.4-11.736 11.952-16.381 6.516-4.645 18.72-8.82 26.748-11.16 7.812-2.447 13.176-2.736 20.376-3.203 7.271-0.576 14.652-0.576 22.716 0-1.512-10.945-3.456-21.133-6.372-31.104-2.844-10.01-6.156-18.254-10.764-28.332-4.788-10.117-10.332-20.665-17.172-31.933-3.889 2.52-7.164 4.608-9.973 5.976-2.808 1.332-3.995 2.232-6.768 2.412-2.88 0.072-6.3-0.468-10.404-1.62-2.88 5.04-6.947 9.036-12.348 11.988-5.616 2.987-12.096 4.896-19.98 5.579-7.884 0.576-18.864-0.539-26.711-1.979-8.064-1.477-15.372-4.249-20.376-6.805-5.04-2.772-7.92-5.652-9.18-9.18-4.176-1.116-8.028-2.268-11.952-3.6-3.924-1.368-7.524-2.772-11.196-4.356-5.22 7.38-9.684 15.444-13.932 24.335-4.248 8.857-7.704 15.984-11.196 28.729-3.574 12.73-6.85 28.1-9.982 47.07z"
                fill="#A16121"
              />
              <path
                d="m287.91 153.7c-2.664 5.868-5.508 11.268-8.388 16.344-3.097 5.076-6.156 10.008-9.181 13.968-3.06 3.852-5.939 6.912-8.783 9.18 3.563 6.228 6.983 12.924 10.403 20.376 3.349 7.415 6.156 12.384 9.973 23.94 3.852 11.52 7.92 26.135 12.744 44.676 8.604 2.52 17.208 4.463 26.352 5.977 9.072 1.439 20.232 3.527 27.936 2.807 7.633-0.756 13.86-3.24 17.568-7.596 3.564-4.5 4.248-11.016 3.996-19.152-0.396-8.316-3.42-19.764-6.012-29.916-2.628-10.26-5.04-21.852-9.576-30.744-4.716-8.964-10.98-15.768-17.964-21.924-7.021-6.228-15.336-10.08-23.544-14.76-8.32-4.7-16.6-9.09-25.53-13.19z"
                fill="#A16121"
              />
              <path
                d="m129.41 420.35c-6.264-2.125-13.536-4.717-20.988-8.604-7.56-3.889-17.172-9.973-23.4-14.51-6.12-4.607-10.044-7.486-13.428-12.924-3.564-5.65-6.732-11.951-7.56-20.16-0.828-8.314 0.36-20.088 2.448-29.051 2.016-9.18 5.652-18.541 9.396-24.768 3.636-6.121 6.372-9 12.096-11.846 5.652-2.986 12.924-4.822 21.276-5.65 8.28-0.865 18.432-1.656 27.684 0.539 9.108 2.125 19.296 6.949 26.388 12.133 6.876 5.219 11.052 11.412 15.048 18.287 3.924 6.625 6.192 13.104 8.604 21.529 2.376 8.387 4.5 19.691 5.4 28.764 0.72 9.072 0.576 18.432-0.828 25.02-1.584 6.301-3.06 9.252-7.776 13.176-4.752 3.852-13.752 7.92-20.196 9.973-6.588 1.871-12.816 1.908-18.54 1.619-5.82-0.33-9.46-1.55-15.61-3.53z"
                fill="#A16121"
              />
              <path d="m151.19 359.83c-9.396-2.988-16.488-1.836-24.192 0.539-7.812 2.268-18.684 7.309-21.78 13.176-3.168 5.869-3.204 15.588 3.492 21.529 6.804 5.76 25.848 11.951 36.864 13.176 10.944 1.08 21.924-1.441 28.224-6.445 6.156-5.111 12.312-16.668 8.604-23.688-3.99-7.14-21.84-15.31-31.2-18.3z" />
              <path
                d="m135.6 362.25c-4.644 1.043-13.392 3.383-18.036 6.479-4.716 2.953-9.972 6.77-9.396 11.305 0.684 4.465 5.184 11.557 12.924 15.588 7.704 3.889 23.22 8.064 32.256 7.813 8.784-0.469 16.38-5.869 20.448-9.973 3.96-4.248 5.544-10.188 3.24-14.508-2.52-4.355-12.276-8.316-17.748-11.053-5.544-2.771-10.8-4.463-14.796-5.363-4.06-1.06-4.38-1.35-8.88-0.3z"
                fill="#834217"
              />
              <path d="m82.858 330.78c-2.448 2.123-6.768 6.443-6.984 11.305-0.144 4.715 0.9 13.787 5.904 17.496 4.968 3.6 18.612 7.199 23.436 4.57 4.716-2.771 6.66-14.58 4.284-20.447-2.628-5.904-15.192-12.42-19.62-14.543-4.42-2.23-4.816-0.58-7.012 1.62z" />
              <path
                d="m88.258 334.56c-2.16 0.648-4.644 2.844-5.652 5.904-1.116 3.061-2.916 8.533-0.54 11.844 2.484 3.24 10.8 6.264 14.796 6.984 3.816 0.504 6.552-1.08 7.812-3.779 1.08-2.809 0.396-9.793-1.08-12.889-1.728-3.203-5.76-4.68-8.352-5.939-2.624-1.35-4.82-2.76-6.98-2.11z"
                fill="#834217"
              />
              <path d="m112.99 317.86c-3.06 0.863-2.196 2.557-1.872 7.02 0.288 4.428 1.548 14.4 3.492 19.369 1.8 4.932 3.852 8.387 7.524 9.684 3.6 1.043 11.592 0.143 14.256-2.988 2.592-3.313 2.448-10.26 1.368-15.588-1.296-5.365-4.176-12.637-8.352-15.588-4.22-2.88-13.4-2.91-16.42-1.9z" />
              <path
                d="m115.44 326.46c-0.468 3.457 2.628 11.125 4.284 15.084 1.476 3.961 2.628 7.523 5.112 8.064 2.448 0.287 8.82-2.232 9.684-5.904 0.756-3.889-2.844-12.6-4.824-16.416-2.052-3.852-4.932-6.047-7.272-6.191-2.52-0.1-6.51 1.88-6.98 5.37z"
                fill="#834217"
              />
              <path d="m148.77 346.12c2.412 3.779 5.328 4.787 8.604 5.363 3.348 0.469 8.928 0.828 11.052-2.123 1.908-3.096 2.448-10.656 0.792-15.877-1.872-5.398-6.984-12.311-11.016-15.336-4.104-2.951-10.26-3.996-12.924-2.16-2.664 1.801-3.6 7.488-2.952 12.637 0.59 5 3.9 13.57 6.45 17.5z" />
              <path
                d="m164.94 338.59c-0.612-2.953-0.864-7.525-3.528-10.225-2.844-2.771-10.296-7.236-12.636-6.191-2.304 1.043-2.376 7.992-1.332 12.096 0.972 4.031 3.996 9.828 6.984 11.844 2.844 1.729 8.424 0.756 10.224-0.539 1.67-1.36 0.84-4.06 0.3-6.98z"
                fill="#834217"
              />
              <path
                d="m274.2 294.13c-5.976-0.252-13.428-0.684-20.269 0.648-7.02 1.225-14.147 3.133-20.556 6.84-6.444 3.492-13.032 7.523-16.992 14.725-3.96 7.271-5.615 18.684-6.516 28.404-1.116 9.611-0.9 19.979 0.647 28.727 1.513 8.568 4.032 15.012 8.82 22.537 4.788 7.488 11.844 17.1 19.908 21.887 7.92 4.537 19.116 5.689 27.756 5.545 8.532-0.252 15.769-2.195 23.508-6.516 7.704-4.357 16.885-10.332 22.536-19.297 5.472-9.18 8.964-23.363 10.477-34.92 1.367-11.736 0.936-24.66-1.98-34.309-3.024-9.756-10.188-17.676-15.66-23.184-5.58-5.4-11.664-7.344-16.992-9.143-5.31-1.9-8.91-1.76-14.7-1.94z"
                fill="#A16121"
              />
              <path d="m260.55 363.07c8.568-0.395 14.473 2.16 20.629 6.014 6.119 3.672 14.256 10.584 15.659 16.307 1.261 5.689-0.936 14.113-7.956 17.641-7.235 3.385-25.02 4.32-34.596 2.809-9.684-1.656-18.504-6.301-22.716-11.988-4.14-5.832-6.876-17.1-1.944-22.285 4.83-5.21 22.22-8.09 30.93-8.49z" />
              <path
                d="m273.37 368.72c3.6 1.908 10.656 6.012 13.968 9.686 3.313 3.563 6.805 8.027 5.473 11.844-1.656 3.779-7.236 8.639-14.652 10.404-7.524 1.547-21.924 1.475-29.448-0.758-7.487-2.482-12.888-8.783-15.228-13.211-2.484-4.537-2.484-10.045 0.576-13.176 3.096-3.168 12.348-4.32 17.748-5.4 5.327-1.115 10.151-1.367 13.896-1.188 3.56-0.06 3.99-0.06 7.66 1.81z"
                fill="#834217"
              />
              <path d="m311.93 330.46c2.305 2.125 6.769 6.443 7.021 11.305 0.107 4.715-1.08 13.787-5.94 17.496-4.968 3.6-18.792 7.199-23.399 4.572-4.752-2.773-6.84-14.58-4.32-20.449 2.592-5.904 15.12-12.42 19.656-14.543 4.35-2.24 4.64-0.58 6.98 1.62z" />
              <path
                d="m306.56 334.24c2.088 0.648 4.571 2.846 5.651 5.904 1.009 3.061 2.809 8.533 0.54 11.844-2.592 3.24-10.908 6.266-14.796 6.984-3.924 0.504-6.624-1.08-7.812-3.779-1.188-2.809-0.504-9.793 1.08-12.889 1.548-3.203 5.615-4.68 8.315-5.939 2.52-1.36 4.86-2.76 7.02-2.11z"
                fill="#834217"
              />
              <path d="m282.77 313.97c2.951 0.793 2.159 2.52 1.907 6.984-0.359 4.428-1.655 14.4-3.491 19.367-1.908 4.934-3.925 8.389-7.561 9.686-3.815 1.152-11.628 0.072-14.256-2.953-2.556-3.275-2.628-10.332-1.332-15.623 1.152-5.365 4.212-12.637 8.352-15.588 4.14-2.93 13.21-2.85 16.38-1.88z" />
              <path
                d="m280.35 322.57c0.396 3.492-2.7 11.053-4.284 15.049-1.548 3.852-2.736 7.561-5.112 8.064-2.556 0.287-8.892-2.232-9.684-5.904-0.828-3.889 2.771-12.6 4.824-16.416 1.979-3.852 4.823-6.049 7.271-6.191 2.41-0.11 6.41 1.84 6.98 5.4z"
                fill="#834217"
              />
              <path d="m247.02 347.02c-1.765 4.211-4.356 5.76-7.381 6.984-3.168 1.08-8.712 2.627-11.231 0.07-2.556-2.734-4.645-9.863-3.924-15.406 0.611-5.473 4.355-13.609 7.775-17.209 3.313-3.744 9.288-5.904 12.24-4.68 2.916 1.26 4.86 6.623 5.4 11.809 0.32 5.12-1.19 14.04-2.88 18.44z" />
              <path
                d="m229.7 342.8c-0.144-3.059-0.684-7.416 1.44-10.691 2.124-3.24 8.567-9.145 11.159-8.568 2.448 0.504 3.816 7.381 3.708 11.592-0.252 4.105-2.088 10.477-4.535 12.996-2.593 2.305-8.101 2.377-10.116 1.477-1.98-1.01-1.66-3.93-1.66-6.81z"
                fill="#834217"
              />
            </g>
          </g>
        </g>
      </React.Fragment>
    ),
    viewBox: `0 0 500 500`,
  },
  box: {
    shape: (
      <path d="M28,2V28H2V2H28m.13-2H1.88A1.88,1.88,0,0,0,0,1.88V28.13A1.88,1.88,0,0,0,1.88,30H28.13A1.87,1.87,0,0,0,30,28.13V1.88A1.88,1.88,0,0,0,28.13,0Z" />
    ),
    viewBox: `0 0 30 30`,
  },
  hexa: {
    shape: (
      <polygon
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="27.5,21.904 15,28.809  2.5,21.904 2.5,8.095 15,1.19 27.5,8.095 "
      />
    ),
    viewBox: `0 0 30 30`,
  },
  teddy: {
    shape: (
      <g>
        <g id="Layer_1">
          <g
            stroke-miterlimit="10"
            stroke="#000"
            stroke-width=".036"
            clip-rule="evenodd"
            fill-rule="evenodd"
          >
            <path d="m123.75 16.501c-9.792-6.84-19.044-10.26-28.044-10.404-9.108-0.144-18.648 4.14-25.848 9.648-7.344 5.436-13.788 14.004-17.1 23.22-3.276 9.18-4.464 22.788-2.196 31.536 2.16 8.64 7.272 15.264 15.336 20.16-1.62 7.164-2.592 13.392-3.06 18.828-0.576 5.436-0.756 7.236 0.432 13.572 1.224 6.3 3.564 14.004 7.02 23.688-16.308 19.584-29.412 37.404-40.284 54.288-10.908 16.668-19.404 29.088-24.12 46.008-4.716 17.027-8.424 43.201-3.924 55.188 4.536 11.736 18.324 15.553 30.672 15.336 12.348-0.322 26.136-6.012 42.48-17.063-5.832 6.875-9.828 15.803-12.276 27.359-2.484 11.557-5.58 28.477-2.196 41.184 3.312 12.529 8.676 23.545 22.356 33.732 13.86 10.115 41.868 24.48 59.58 26.713 17.496 2.051 35.82-6.373 45.108-14.006 9.071-7.775 9-20.051 9.647-31.967 0.576-12.023-1.512-24.984-6.12-39.457h12.276c-2.16 9.938-2.268 19.549-0.468 29.377 1.8 9.791 5.651 19.943 11.412 28.908 5.688 8.82 11.483 18.828 22.788 23.652 11.34 4.68 31.14 7.344 44.243 4.391 12.925-3.131 24.841-12.852 32.832-22.787 7.668-10.188 11.881-23.617 14.04-36.793 2.124-13.211 1.044-30.131-0.899-41.184-2.052-11.088-5.652-17.424-10.8-24.299-5.221-6.949-11.736-12.385-19.944-16.74 10.836 2.664 21.563 4.607 32.724 5.975 11.053 1.332 25.416 5.508 33.517 1.98 8.027-3.816 13.68-11.123 14.472-23.867 0.54-12.816-5.184-36.865-10.512-52.129-5.364-15.335-8.208-27.216-21.024-38.988-13.032-11.88-31.355-22.212-56.088-31.572 5.148-14.112 8.316-26.784 9.648-38.844 1.296-11.988 0.756-22.896-1.765-32.868 12.924-3.564 20.772-9.648 24.084-18.396 3.168-8.964 1.836-24.372-4.824-34.164-6.911-9.756-22.896-22.14-35.46-23.688-12.6-1.584-25.271 3.24-38.987 14.472-8.676-5.616-19.152-9.612-32.004-12.276-13.032-2.7-30.061-5.58-44.676-3.492-14.59 2.093-28.38 7.205-42.06 15.773z" />
            <path
              d="m146.25 39.865c-10.512-7.848-19.368-13.968-27.144-18.756-7.704-4.788-12.42-8.82-19.152-9.576-6.912-0.864-15.624 1.188-21.96 4.788-6.444 3.636-12.492 10.296-16.344 16.776-4.032 6.444-6.66 14.184-7.2 21.132-0.576 6.768 0.756 13.932 3.996 19.548 3.204 5.472 8.208 10.044 15.156 13.572-2.448 4.68-3.96 10.044-4.392 16.38-0.468 6.336 0.252 13.752 1.62 21.132 1.26 7.236 1.764 14.508 5.976 21.96 4.248 7.416 12.816 15.804 18.756 21.96 5.724 5.976 8.172 9.288 15.948 13.968 7.776 4.608 17.712 9.072 30.348 13.536-1.404-4.5-2.52-8.568-3.204-12.744-0.792-4.284-3.132-9.108-1.188-11.988 1.872-2.952 4.284-2.556 12.78-5.184 8.568-2.736 27.684-9.792 37.908-10.764 9.9-1.008 13.788 1.512 22.355 4.788 8.568 3.312 25.129 9.54 29.124 14.364 3.744 4.644-3.672 9.18-5.579 13.176-1.872 3.852-3.564 7.128-5.221 9.972 5.904 1.512 12.061 0.18 18.756-3.996 6.66-4.284 14.832-12.78 21.168-21.168 6.408-8.532 12.133-20.232 16.345-29.124 3.924-8.856 6.084-15.552 7.596-23.544 1.404-8.28 1.296-17.316 1.188-24.732-0.181-7.38-0.792-13.716-1.98-19.152 6.012-1.8 11.196-4.644 15.552-8.388 4.356-3.852 9.108-8.46 10.368-14.364 1.044-6.012 0.036-14.508-3.563-21.168-3.673-6.696-11.881-14.508-17.965-18.72-6.264-4.248-12.203-6.336-18.359-6.804-6.12-0.612-9.937-1.44-17.964 3.204-8.137 4.572-18.108 12.636-30.313 24.336l-6.804-3.204c1.728-1.26 3.996-2.988 6.408-5.184 2.376-2.196 5.292-4.86 8.352-7.992-7.596-4.176-16.056-7.056-25.523-9.18-9.648-2.16-20.952-3.312-31.141-3.168-10.332 0.108-20.772 1.44-29.52 3.996-8.64 2.484-15.912 5.976-21.96 10.764l19.944 14.364c-1.07 1.711-2.12 3.439-3.2 5.167z"
              fill="#A16121"
            />
            <path d="m129.08 50.233c-7.668-6.156-14.796-10.188-21.924-11.952-7.236-1.908-15.228-1.512-20.376 1.188-5.22 2.736-9.792 8.208-9.972 14.76-0.144 6.408 2.916 14.112 9.18 23.544 12.024-1.08 21.456-3.78 28.728-8.388 7.23-4.608 11.88-10.944 14.36-19.152z" />
            <path
              d="m122.71 51.457c-3.528-3.384-8.1-5.652-13.572-6.804-5.616-1.296-15.228-1.62-19.548-0.396-4.356 1.224-6.444 3.42-6.408 7.992 0.036 4.536 2.304 10.872 6.804 19.152 9.828-1.8 17.136-4.284 22.752-7.596 5.47-3.384 8.75-7.452 9.97-12.348z"
              fill="#834217"
            />
            <path d="m242.41 39.433c8.172-5.472 15.696-8.748 23.004-9.828 7.236-1.152 15.192-0.072 20.124 3.096 4.716 3.096 9 9.144 8.568 15.624-0.504 6.408-4.248 13.788-11.376 22.572-11.916-2.196-20.988-5.832-27.792-11.052-6.72-5.256-10.9-12.06-12.52-20.412z" />
            <path
              d="m248.67 41.233c3.889-2.988 8.425-4.86 14.148-5.472 5.652-0.792 15.191-0.18 19.476 1.44 4.177 1.62 6.048 3.996 5.616 8.532-0.504 4.5-3.348 10.548-8.532 18.432-9.647-2.7-16.775-5.832-21.96-9.684-5.16-3.888-8.07-8.209-8.76-13.248z"
              fill="#834217"
            />
            <path d="m129.73 135.52c1.548 9.108 9.756 15.336 18.36 13.86 8.568-1.476 14.256-10.044 12.708-19.188-1.584-9.108-9.792-15.336-18.36-13.86-8.61 1.47-14.29 10.04-12.71 19.19z" />
            <path
              d="m135.2 133.82c1.044 6.084 6.516 10.224 12.24 9.252s9.54-6.696 8.496-12.78-6.516-10.224-12.24-9.252c-5.73 0.98-9.54 6.7-8.5 12.78z"
              fill="#fff"
            />
            <path d="m139.56 136.92c0.756 4.356 4.644 7.308 8.748 6.624 4.068-0.72 6.804-4.824 6.048-9.144-0.756-4.356-4.644-7.308-8.748-6.624-4.07 0.72-6.81 4.78-6.05 9.14z" />
            <path d="m208.14 133.79c-0.611 9.252 5.94 17.208 14.616 17.784s16.236-6.444 16.849-15.696c0.611-9.216-5.94-17.172-14.616-17.748-8.72-0.57-16.24 6.45-16.85 15.67z" />
            <path
              d="m213.86 133.46c-0.396 6.156 3.96 11.448 9.756 11.844s10.836-4.284 11.232-10.44c0.396-6.156-3.96-11.484-9.757-11.88-5.79-0.35-10.8 4.33-11.23 10.48z"
              fill="#fff"
            />
            <path d="m217.39 137.46c-0.324 4.428 2.808 8.208 6.947 8.46 4.141 0.288 7.74-3.06 8.028-7.452s-2.844-8.172-6.984-8.46c-4.15-0.29-7.71 3.06-8 7.45z" />
            <path
              d="m168.61 164.86c-4.464 1.26-8.856 2.412-13.176 3.996-4.428 1.368-10.764 2.088-12.78 4.788-2.088 2.808 0.072 7.452 0.792 11.592 0.612 3.996 0.432 8.064 3.204 12.348 2.592 4.32 6.804 10.152 13.176 13.176 6.372 2.772 20.124 5.724 25.128 3.996 4.968-1.836 6.3-6.48 4.392-14.364-4.14-4.212-7.2-7.668-9.576-10.764-2.412-3.024-5.04-5.364-4.392-7.596 0.648-2.484 4.284-5.256 7.992-6.372 3.636-1.116 8.856-1.152 13.572-0.396 4.536 0.684 12.527 1.08 13.968 4.392 1.296 3.24-3.168 11.124-5.616 14.76-2.483 3.492-5.544 5.616-9.18 6.372-2.196 5.364-2.592 9-1.188 11.592 1.404 2.448 5.616 4.248 9.576 3.168 3.853-1.296 10.044-6.516 13.572-9.972 3.312-3.42 4.932-6.408 7.2-10.368 2.304-4.176 6.048-10.584 6.768-13.968 0.72-3.312 0.72-3.636-2.771-5.976-3.816-2.628-13.5-6.3-19.188-8.784-5.688-2.52-9.828-4.752-14.364-5.58-4.607-0.72-8.711-0.036-13.14 0.792-4.56 0.64-9.17 1.64-13.96 3.16z"
              fill="#834217"
            />
            <path
              d="m72.418 151.21c-6.12 7.812-12.672 16.848-19.944 27.504-7.452 10.62-17.244 25.704-23.544 35.928-6.228 10.08-10.152 15.408-13.572 24.335-3.636 8.857-6.264 19.404-7.596 28.729-1.404 9.217-2.88 19.801-0.396 26.748 2.556 6.877 9.144 12.133 15.192 14.363 5.76 2.053 11.016 1.297 19.944-1.584 9-2.879 19.944-8.063 33.516-15.551 0.468-13.465 2.412-26.1 5.976-38.736 3.528-12.707 9.972-25.955 15.156-36.287 5.112-10.369 10.044-18.577 15.192-25.165-7.812-5.076-14.868-10.8-21.564-17.568-6.694-6.84-12.814-14.19-18.358-22.72z"
              fill="#A16121"
            />
            <path
              d="m81.202 294.96c5.832-3.131 12.888-5.256 21.564-6.371 8.604-1.152 21.492-1.117 29.916-0.432 8.352 0.539 12.96 1.078 19.944 3.994 6.876 2.916 16.02 8.426 21.168 13.178 4.968 4.607 6.408 9.07 9.18 14.363 2.736 5.184 5.076 10.729 7.164 16.775l12.385 0.396c0.576-6.48 2.304-12.6 5.184-18.359 2.844-5.904 5.4-11.736 11.952-16.381 6.516-4.645 18.72-8.82 26.748-11.16 7.812-2.447 13.176-2.736 20.376-3.203 7.271-0.576 14.652-0.576 22.716 0-1.512-10.945-3.456-21.133-6.372-31.104-2.844-10.01-6.156-18.254-10.764-28.332-4.788-10.117-10.332-20.665-17.172-31.933-3.889 2.52-7.164 4.608-9.973 5.976-2.808 1.332-3.995 2.232-6.768 2.412-2.88 0.072-6.3-0.468-10.404-1.62-2.88 5.04-6.947 9.036-12.348 11.988-5.616 2.987-12.096 4.896-19.98 5.579-7.884 0.576-18.864-0.539-26.711-1.979-8.064-1.477-15.372-4.249-20.376-6.805-5.04-2.772-7.92-5.652-9.18-9.18-4.176-1.116-8.028-2.268-11.952-3.6-3.924-1.368-7.524-2.772-11.196-4.356-5.22 7.38-9.684 15.444-13.932 24.335-4.248 8.857-7.704 15.984-11.196 28.729-3.574 12.73-6.85 28.1-9.982 47.07z"
              fill="#A16121"
            />
            <path
              d="m287.91 153.7c-2.664 5.868-5.508 11.268-8.388 16.344-3.097 5.076-6.156 10.008-9.181 13.968-3.06 3.852-5.939 6.912-8.783 9.18 3.563 6.228 6.983 12.924 10.403 20.376 3.349 7.415 6.156 12.384 9.973 23.94 3.852 11.52 7.92 26.135 12.744 44.676 8.604 2.52 17.208 4.463 26.352 5.977 9.072 1.439 20.232 3.527 27.936 2.807 7.633-0.756 13.86-3.24 17.568-7.596 3.564-4.5 4.248-11.016 3.996-19.152-0.396-8.316-3.42-19.764-6.012-29.916-2.628-10.26-5.04-21.852-9.576-30.744-4.716-8.964-10.98-15.768-17.964-21.924-7.021-6.228-15.336-10.08-23.544-14.76-8.32-4.7-16.6-9.09-25.53-13.19z"
              fill="#A16121"
            />
            <path
              d="m129.41 420.35c-6.264-2.125-13.536-4.717-20.988-8.604-7.56-3.889-17.172-9.973-23.4-14.51-6.12-4.607-10.044-7.486-13.428-12.924-3.564-5.65-6.732-11.951-7.56-20.16-0.828-8.314 0.36-20.088 2.448-29.051 2.016-9.18 5.652-18.541 9.396-24.768 3.636-6.121 6.372-9 12.096-11.846 5.652-2.986 12.924-4.822 21.276-5.65 8.28-0.865 18.432-1.656 27.684 0.539 9.108 2.125 19.296 6.949 26.388 12.133 6.876 5.219 11.052 11.412 15.048 18.287 3.924 6.625 6.192 13.104 8.604 21.529 2.376 8.387 4.5 19.691 5.4 28.764 0.72 9.072 0.576 18.432-0.828 25.02-1.584 6.301-3.06 9.252-7.776 13.176-4.752 3.852-13.752 7.92-20.196 9.973-6.588 1.871-12.816 1.908-18.54 1.619-5.82-0.33-9.46-1.55-15.61-3.53z"
              fill="#A16121"
            />
            <path d="m151.19 359.83c-9.396-2.988-16.488-1.836-24.192 0.539-7.812 2.268-18.684 7.309-21.78 13.176-3.168 5.869-3.204 15.588 3.492 21.529 6.804 5.76 25.848 11.951 36.864 13.176 10.944 1.08 21.924-1.441 28.224-6.445 6.156-5.111 12.312-16.668 8.604-23.688-3.99-7.14-21.84-15.31-31.2-18.3z" />
            <path
              d="m135.6 362.25c-4.644 1.043-13.392 3.383-18.036 6.479-4.716 2.953-9.972 6.77-9.396 11.305 0.684 4.465 5.184 11.557 12.924 15.588 7.704 3.889 23.22 8.064 32.256 7.813 8.784-0.469 16.38-5.869 20.448-9.973 3.96-4.248 5.544-10.188 3.24-14.508-2.52-4.355-12.276-8.316-17.748-11.053-5.544-2.771-10.8-4.463-14.796-5.363-4.06-1.06-4.38-1.35-8.88-0.3z"
              fill="#834217"
            />
            <path d="m82.858 330.78c-2.448 2.123-6.768 6.443-6.984 11.305-0.144 4.715 0.9 13.787 5.904 17.496 4.968 3.6 18.612 7.199 23.436 4.57 4.716-2.771 6.66-14.58 4.284-20.447-2.628-5.904-15.192-12.42-19.62-14.543-4.42-2.23-4.816-0.58-7.012 1.62z" />
            <path
              d="m88.258 334.56c-2.16 0.648-4.644 2.844-5.652 5.904-1.116 3.061-2.916 8.533-0.54 11.844 2.484 3.24 10.8 6.264 14.796 6.984 3.816 0.504 6.552-1.08 7.812-3.779 1.08-2.809 0.396-9.793-1.08-12.889-1.728-3.203-5.76-4.68-8.352-5.939-2.624-1.35-4.82-2.76-6.98-2.11z"
              fill="#834217"
            />
            <path d="m112.99 317.86c-3.06 0.863-2.196 2.557-1.872 7.02 0.288 4.428 1.548 14.4 3.492 19.369 1.8 4.932 3.852 8.387 7.524 9.684 3.6 1.043 11.592 0.143 14.256-2.988 2.592-3.313 2.448-10.26 1.368-15.588-1.296-5.365-4.176-12.637-8.352-15.588-4.22-2.88-13.4-2.91-16.42-1.9z" />
            <path
              d="m115.44 326.46c-0.468 3.457 2.628 11.125 4.284 15.084 1.476 3.961 2.628 7.523 5.112 8.064 2.448 0.287 8.82-2.232 9.684-5.904 0.756-3.889-2.844-12.6-4.824-16.416-2.052-3.852-4.932-6.047-7.272-6.191-2.52-0.1-6.51 1.88-6.98 5.37z"
              fill="#834217"
            />
            <path d="m148.77 346.12c2.412 3.779 5.328 4.787 8.604 5.363 3.348 0.469 8.928 0.828 11.052-2.123 1.908-3.096 2.448-10.656 0.792-15.877-1.872-5.398-6.984-12.311-11.016-15.336-4.104-2.951-10.26-3.996-12.924-2.16-2.664 1.801-3.6 7.488-2.952 12.637 0.59 5 3.9 13.57 6.45 17.5z" />
            <path
              d="m164.94 338.59c-0.612-2.953-0.864-7.525-3.528-10.225-2.844-2.771-10.296-7.236-12.636-6.191-2.304 1.043-2.376 7.992-1.332 12.096 0.972 4.031 3.996 9.828 6.984 11.844 2.844 1.729 8.424 0.756 10.224-0.539 1.67-1.36 0.84-4.06 0.3-6.98z"
              fill="#834217"
            />
            <path
              d="m274.2 294.13c-5.976-0.252-13.428-0.684-20.269 0.648-7.02 1.225-14.147 3.133-20.556 6.84-6.444 3.492-13.032 7.523-16.992 14.725-3.96 7.271-5.615 18.684-6.516 28.404-1.116 9.611-0.9 19.979 0.647 28.727 1.513 8.568 4.032 15.012 8.82 22.537 4.788 7.488 11.844 17.1 19.908 21.887 7.92 4.537 19.116 5.689 27.756 5.545 8.532-0.252 15.769-2.195 23.508-6.516 7.704-4.357 16.885-10.332 22.536-19.297 5.472-9.18 8.964-23.363 10.477-34.92 1.367-11.736 0.936-24.66-1.98-34.309-3.024-9.756-10.188-17.676-15.66-23.184-5.58-5.4-11.664-7.344-16.992-9.143-5.31-1.9-8.91-1.76-14.7-1.94z"
              fill="#A16121"
            />
            <path d="m260.55 363.07c8.568-0.395 14.473 2.16 20.629 6.014 6.119 3.672 14.256 10.584 15.659 16.307 1.261 5.689-0.936 14.113-7.956 17.641-7.235 3.385-25.02 4.32-34.596 2.809-9.684-1.656-18.504-6.301-22.716-11.988-4.14-5.832-6.876-17.1-1.944-22.285 4.83-5.21 22.22-8.09 30.93-8.49z" />
            <path
              d="m273.37 368.72c3.6 1.908 10.656 6.012 13.968 9.686 3.313 3.563 6.805 8.027 5.473 11.844-1.656 3.779-7.236 8.639-14.652 10.404-7.524 1.547-21.924 1.475-29.448-0.758-7.487-2.482-12.888-8.783-15.228-13.211-2.484-4.537-2.484-10.045 0.576-13.176 3.096-3.168 12.348-4.32 17.748-5.4 5.327-1.115 10.151-1.367 13.896-1.188 3.56-0.06 3.99-0.06 7.66 1.81z"
              fill="#834217"
            />
            <path d="m311.93 330.46c2.305 2.125 6.769 6.443 7.021 11.305 0.107 4.715-1.08 13.787-5.94 17.496-4.968 3.6-18.792 7.199-23.399 4.572-4.752-2.773-6.84-14.58-4.32-20.449 2.592-5.904 15.12-12.42 19.656-14.543 4.35-2.24 4.64-0.58 6.98 1.62z" />
            <path
              d="m306.56 334.24c2.088 0.648 4.571 2.846 5.651 5.904 1.009 3.061 2.809 8.533 0.54 11.844-2.592 3.24-10.908 6.266-14.796 6.984-3.924 0.504-6.624-1.08-7.812-3.779-1.188-2.809-0.504-9.793 1.08-12.889 1.548-3.203 5.615-4.68 8.315-5.939 2.52-1.36 4.86-2.76 7.02-2.11z"
              fill="#834217"
            />
            <path d="m282.77 313.97c2.951 0.793 2.159 2.52 1.907 6.984-0.359 4.428-1.655 14.4-3.491 19.367-1.908 4.934-3.925 8.389-7.561 9.686-3.815 1.152-11.628 0.072-14.256-2.953-2.556-3.275-2.628-10.332-1.332-15.623 1.152-5.365 4.212-12.637 8.352-15.588 4.14-2.93 13.21-2.85 16.38-1.88z" />
            <path
              d="m280.35 322.57c0.396 3.492-2.7 11.053-4.284 15.049-1.548 3.852-2.736 7.561-5.112 8.064-2.556 0.287-8.892-2.232-9.684-5.904-0.828-3.889 2.771-12.6 4.824-16.416 1.979-3.852 4.823-6.049 7.271-6.191 2.41-0.11 6.41 1.84 6.98 5.4z"
              fill="#834217"
            />
            <path d="m247.02 347.02c-1.765 4.211-4.356 5.76-7.381 6.984-3.168 1.08-8.712 2.627-11.231 0.07-2.556-2.734-4.645-9.863-3.924-15.406 0.611-5.473 4.355-13.609 7.775-17.209 3.313-3.744 9.288-5.904 12.24-4.68 2.916 1.26 4.86 6.623 5.4 11.809 0.32 5.12-1.19 14.04-2.88 18.44z" />
            <path
              d="m229.7 342.8c-0.144-3.059-0.684-7.416 1.44-10.691 2.124-3.24 8.567-9.145 11.159-8.568 2.448 0.504 3.816 7.381 3.708 11.592-0.252 4.105-2.088 10.477-4.535 12.996-2.593 2.305-8.101 2.377-10.116 1.477-1.98-1.01-1.66-3.93-1.66-6.81z"
              fill="#834217"
            />
          </g>
        </g>
      </g>
    ),
    viewBox: `0 0 500 500`,
  },
  twitter: {
    share: (
      <path
        strokeWidth="3px"
        d="M60.5,50l34.8-34.8c2.9-2.9,2.9-7.6,0-10.5c-2.9-2.9-7.6-2.9-10.5,0L50,39.5L15.2,4.7c-2.9-2.9-7.6-2.9-10.5,0    c-2.9,2.9-2.9,7.6,0,10.5L39.5,50L4.7,84.8c-2.9,2.9-2.9,7.6,0,10.5c1.4,1.4,3.3,2.2,5.2,2.2s3.8-0.7,5.2-2.2L50,60.5l34.8,34.8    c1.4,1.4,3.3,2.2,5.2,2.2c1.9,0,3.8-0.7,5.2-2.2c2.9-2.9,2.9-7.6,0-10.5L60.5,50z"
      />
    ),
  },
  insta: {
    shape: (
      <path
        strokeWidth="3px"
        d="M60.5,50l34.8-34.8c2.9-2.9,2.9-7.6,0-10.5c-2.9-2.9-7.6-2.9-10.5,0L50,39.5L15.2,4.7c-2.9-2.9-7.6-2.9-10.5,0    c-2.9,2.9-2.9,7.6,0,10.5L39.5,50L4.7,84.8c-2.9,2.9-2.9,7.6,0,10.5c1.4,1.4,3.3,2.2,5.2,2.2s3.8-0.7,5.2-2.2L50,60.5l34.8,34.8    c1.4,1.4,3.3,2.2,5.2,2.2c1.9,0,3.8-0.7,5.2-2.2c2.9-2.9,2.9-7.6,0-10.5L60.5,50z"
      />
    ),
  },
};

type IconType =
  | 'triangle'
  | 'circle'
  | 'arrowUp'
  | 'upDown'
  | 'box'
  | 'hexa'
  | 'teddy';

type SVGProps = {
  stroke?: boolean;
  color?: string | number | any;
  width: number;
  icon: IconType | string;
  left: string;
  top: string;
  hiddenMobile?: boolean;
  style: any;
  isImage?: boolean;
};

const SVG = ({
  stroke = false,
  color = ``,
  width,
  icon,
  left,
  top,
  hiddenMobile = false,
  isImage = false,
  style,
}: SVGProps) => {
  if (isImage) {
    return (
      <img
        src={icon}
        sx={{
          position: `absolute`,
          display: hiddenMobile ? hidden : `block`,
          width,
          left,
          top,
          ...style,
        }}
      ></img>
    );
  } else {
    return (
      <svg
        sx={{
          position: `absolute`,
          stroke: stroke ? `currentColor` : `none`,
          fill: stroke ? `none` : `currentColor`,
          display: hiddenMobile ? hidden : `block`,
          color,
          width,
          left,
          top,
          ...style,
        }}
        viewBox={icons[icon].viewBox}
      >
        {icons[icon].shape}
      </svg>
    );
  }
};

export default SVG;
